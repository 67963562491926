const FAQQuestions = (i18n) => [
  {
    id: '1',
    title: i18n.gettext('¿Qué es el Partners Program?'),
    response: i18n.gettext(
      'Es un programa para desarrolladores, gratuito y exclusivo, creado para que formes parte de nuestra comunidad y accedas a beneficios extras.',
    ),
  },
  {
    id: '2',
    title: i18n.gettext('¿Cómo formo parte del programa?'),
    response: i18n.gettext(
      'Debés tener aprobada, al menos, una de nuestras certificaciones oficiales. No es obligatorio tener todas.',
    ),
  },
  {
    id: '3',
    title: i18n.gettext('¿Tiene un costo formar parte del programa?'),
    response: i18n.gettext(
      'No, es gratuito. Solo debes tener aprobada, al menos, una de nuestras certificaciones oficiales.',
    ),
  },
  {
    id: '4',
    title: i18n.gettext(
      'Si ya tengo hechas integraciones, ¿debo certificarme?',
    ),
    response: i18n.gettext(
      'Sí, es necesario que te certifiques para recibir tu Integrator ID y que puedas colocarlo en todas tus integraciones.',
    ),
  },
  {
    id: '5',
    title: i18n.gettext(
      '¿Qué es y para qué sirve el Integrator ID que me entregan junto a mi certificado oficial?',
    ),
    response: i18n.gettext(
      'El Integrator ID es un código único de identificación. Sirve para identificarte como miembro del programa y poder acceder a los beneficios extras.',
    ),
  },
  {
    id: '6',
    title: i18n.gettext(
      'Si ya tengo cuentas integradas, ¿entran dentro del esquema de bonificación y tasas preferenciales?',
    ),
    response: i18n.gettext(
      'No, las bonificaciones y las tasas preferenciales se tienen en cuenta para las integraciones que hagas después de haber finalizado la certificación. Sin embargo, es necesario que coloques el Integrator ID en todas tus integraciones.',
    ),
    sites: ['MLM', 'MLB', 'MLC', 'MLU', 'MLV'],
  },
  {
    id: '7',
    title: i18n.gettext('¿Cuáles son los Términos y condiciones del programa?'),
    response: i18n.jsx.gettext(
      'Descarga y conoce los {0}Términos y condiciones{1} del programa.',
      {
        tags: {
          0: '<a class="certifications--link" href="https://http2.mlstatic.com/frontend-assets/dx-devsite/devprogram/TYC%20MLM.pdf" target="_blank">',
          1: '</a>',
        },
        wrapper: 'span',
      },
    ),
    sites: ['MLM'],
  },
  {
    id: '8',
    title: i18n.gettext(
      '¿Es necesario tener una cuenta de Mercado Pago para participar del programa?',
    ),
    response: i18n.gettext(
      'Sí, necesitas tener una cuenta de Mercado Pago para participar del programa y recibir el incentivo financiero.',
    ),
    sites: ['MLB'],
  },
  {
    id: '9',
    title: i18n.gettext('¿Qué son los niveles de partners?'),
    response: i18n.jsx.gettext(
      'Los niveles de partner son los grados asignados a tu perfil de desarrollador, que definirán tus condiciones de remuneración por cliente referido. Varían según el valor financiero mensual total de todas tus integraciones y se miden cada 3 y 6 meses. {0}Saber más{1}',
      {
        tags: {
          0: '<a class="certifications--link" href="https://www.mercadopago.com.br/partners/developers/pt/details" target="_blank">',
          1: '</a>',
        },
        wrapper: 'span',
      },
    ),
    sites: ['MLB'],
  },
  {
    id: '10',
    title: i18n.gettext(
      '¿Cómo se calcula el incentivo financiero del programa de recompensas?',
    ),
    response: i18n.jsx.gettext(
      'El incentivo financiero se calcula en función del volumen de transacciones de tus clientes recomendados y su nivel de certificación. {0}Saber más{1}',
      {
        tags: {
          0: '<a class="certifications--link" href="https://www.mercadopago.com.br/partners/developers/pt/details" target="_blank">',
          1: '</a>',
        },
        wrapper: 'span',
      },
    ),
    sites: ['MLB'],
  },
  {
    id: '11',
    title: i18n.gettext(
      '¿Cómo recibo y cuál es el plazo para recibir incentivos financieros del Programa de Recompensas?',
    ),
    response: i18n.jsx.gettext('{0}Saber más{1}', {
      tags: {
        0: '<a class="certifications--link" href="https://www.mercadopago.com.br/partners/developers/pt/details" target="_blank">',
        1: '</a>',
      },
      wrapper: 'span',
    }),
    sites: ['MLB'],
  },
];

const getFAQQuestions = (i18n, siteId) =>
  FAQQuestions(i18n).filter(
    (item) => !item.sites || item.sites.includes(siteId.toUpperCase()),
  );

export { getFAQQuestions, FAQQuestions };
