import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

const CertificationImage = ({ image }) => (
  <Image
    className="certification--content-card-image"
    src={`developers/certifications/${image}`}
    alt=""
  />
);

CertificationImage.propTypes = {
  image: PropTypes.string.isRequired,
};

export default CertificationImage;
