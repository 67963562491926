import React from 'react';
import PropTypes from 'prop-types';

import PartnersBenefits from './PartnersBenefits';
import ClientsBenefits from './ClientsBenefits';
import EconomicBenefits from './EconomicBenefits';
import Introduction from './Introduction';
import FAQ from './FAQ';
import Certifications from './Certifications';
import Steps from './Steps';

const Home = ({
  i18n,
  benefits,
  lang,
  questions,
  clientsBenefits,
  melidataPath,
  certifications,
  isMobile,
  country,
}) => (
  <section className="s-info">
    <Introduction i18n={i18n} melidataPath={melidataPath} country={country} />
    <PartnersBenefits
      i18n={i18n}
      lang={lang}
      benefits={benefits}
      melidataPath={melidataPath}
    />
    <ClientsBenefits
      i18n={i18n}
      clientsBenefits={clientsBenefits}
      country={country}
      isMobile={isMobile}
    />
    <Steps i18n={i18n} melidataPath={melidataPath} isMobile={isMobile} />
    <EconomicBenefits
      i18n={i18n}
      lang={lang}
      melidataPath={melidataPath}
      country={country}
    />
    <Certifications
      i18n={i18n}
      certifications={certifications}
      melidataPath={melidataPath}
    />
    <FAQ
      i18n={i18n}
      questions={questions}
      maxItems={3}
      melidataPath={melidataPath}
      country={country}
      lang={lang}
    />
  </section>
);

Home.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  lang: PropTypes.string,
  clientsBenefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      img: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  melidataPath: PropTypes.string,
  certifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      requirements: PropTypes.arrayOf(PropTypes.string),
      link: PropTypes.string,
      image: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  isMobile: PropTypes.bool,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  country: PropTypes.string,
};

export default Home;
