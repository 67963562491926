import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@andes/button';

import Tracker from '../../../../Utils/tracker';
import getTextsByCountry from '../../../../../texts/getTexts';

const HeaderContent = ({ i18n, melidataPath, country, isYellowTheme }) => (
  <div className="header--content">
    <span className="header--content-program" label="platforms" size="small">
      {i18n.gettext('Programa de partners')}
    </span>
    <h1
      className={`header--content-title ${
        isYellowTheme ? 'header--content-title--rebrand' : ''
      }`}
    >
      {getTextsByCountry(country, 'HeaderContent_title', i18n)}
    </h1>
    <p className="header--content-details">
      {getTextsByCountry(country, 'HeaderContent_details', i18n)}
    </p>
    <Button
      className="header--content-action"
      href="#developers-steps"
      hierarchy="transparent"
      onClick={() => {
        Tracker.trackAnalyticsEvent({
          label: 'ACQUISITION_LANDING_DEVELOPERS_HEADER_ACTION_CLICK',
        });
        Tracker.melidataTrack(melidataPath, {
          label: 'ACQUISITION_LANDING_DEVELOPERS_HEADER_ACTION_CLICK',
        });
      }}
    >
      {i18n.gettext('Cómo participar del programa')}
    </Button>
  </div>
);

HeaderContent.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  melidataPath: PropTypes.string,
  country: PropTypes.string,
  isYellowTheme: PropTypes.bool.isRequired,
};

export default HeaderContent;
