/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/require-await */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { GTMNoScript, GTMScript } from '@landings-library/google-tag-manager';
import { Hotjar } from 'nordic/hotjar';
import config from 'nordic/config';
import { useI18n } from 'nordic/i18n';

import Home from '../../../../components/developers/Home';
import HeaderHero from '../../../../components/developers/HeaderHero';
import Footer from '../../../../components/developers/Footer';
import Footnotes from '../../../../components/developers/Footnotes';
// Configs
import { getBenefits } from '../../../../components/developers/Home/config/config-benefits';
import { getFAQQuestions } from '../../../../components/developers/Home/config/config-faqs';
import { getClientsBenefits } from '../../../../components/developers/Home/config/config-clients-benefits';
import { getCertifications } from '../../../../components/developers/Home/config/config-certifications';

const Partners = (props) => {
  const {
    gtmId,
    hotjar,
    lang,
    countryId,
    melidataPath,
    siteId,
    deviceType,
    isYellowTheme,
  } = props;
  const country = countryId?.toLowerCase();
  const { i18n } = useI18n();
  const [isMobile, setIsMobile] = useState(false);

  const benefits = getBenefits(i18n, siteId, country);
  const questions = getFAQQuestions(i18n, siteId);
  const clientsBenefits = getClientsBenefits(i18n, siteId, country, lang);
  const certifications = getCertifications(i18n, siteId, lang);

  useEffect(() => setIsMobile(deviceType === 'mobile'), []);

  return (
    <>
      <HeaderHero
        i18n={i18n}
        lang={lang}
        country={country}
        melidataPath={melidataPath}
        isMobile={isMobile}
        isYellowTheme={isYellowTheme}
      />
      <Home
        i18n={i18n}
        benefits={benefits}
        lang={lang}
        questions={questions}
        clientsBenefits={clientsBenefits}
        country={country}
        melidataPath={melidataPath}
        certifications={certifications}
        isMobile={isMobile}
      />
      <Footer
        i18n={i18n}
        background="white"
        lang={lang}
        country={country}
        melidataPath={melidataPath}
        analyticsLabel="ACQUISITION_LANDING_DEVELOPERS_FOOTER_ACTION_CLICK"
        isYellowTheme={isYellowTheme}
      />
      <Footnotes i18n={i18n} country={country} />
      <GTMNoScript gtmId={gtmId} />
      <GTMScript gtmId={gtmId} />
      <Hotjar {...hotjar} />
    </>
  );
};

export const hydrate = 'hydrateRoot';

export const getServerSideProps = async (req, res) => {
  const { i18n, device, platform, params } = req;
  const { gtmId, hotjar, melidata } = config;
  const { lang } = res.locals;
  const { countryId } = platform;
  const deviceType = device.type;
  const { siteId } = platform;
  const typeTitle = params?.lang === 'es' ? 'Developers' : 'Desenvolvedores';

  const isYellowTheme = req?.mercadopago?.brand === 'yellowblue-light';

  const melidataWithEventData = {
    path: melidata.pathDevelopers,
    event_data: { label: 'DEVELOPERS_HOME' },
  };

  // Validation feature flag rebranding
  const suffix =
    req?.mercadopago?.brand === 'yellowblue-light'
      ? 'yellow-theme/'
      : 'blue-theme/';

  return {
    props: {
      gtmId,
      hotjar,
      lang,
      countryId,
      deviceType,
      siteId,
      melidataPath: melidata.pathDevelopersAction,
      userData: {
        name: res.locals.name,
        avatar: res.locals.avatar,
        isDevCetificate: res.locals.isDevCertificate,
        id: req.auth?.user?.id,
        email: res.locals.email,
      },
      actualUrl: {
        host: `https://${req.get('host')}`,
        path: req.baseUrl + req.path,
        fullPath: req.originalUrl,
        localPath: req.path,
      },
      isYellowTheme,
    },
    settings: {
      title: `${i18n?.gettext('Programa de Partners')} | ${typeTitle}`,
      melidataWithEventData,
      imagesPrefix: config.assets.prefix + suffix,
    },
  };
};

export const setPageSettings = ({ settings }) => ({
  id: 'developers-home',
  className: 'home partners',
  title: settings.title,
  imagesPrefix: settings?.imagesPrefix,
  melidata: settings.melidataWithEventData,
  navigation: {
    type: 'full',
  },
  layout: {
    staticMarkup: false,
  },
});

Partners.propTypes = {
  gtmId: PropTypes.string,
  hotjar: PropTypes.shape({
    id: PropTypes.number,
    version: PropTypes.number,
  }),
  lang: PropTypes.string,
  countryId: PropTypes.string,
  melidataPath: PropTypes.string,
  siteId: PropTypes.string,
  deviceType: PropTypes.string,
  isYellowTheme: PropTypes.bool,
};

export default Partners;
