import React from 'react';
import PropTypes from 'prop-types';

import IntroContent from './components/IntroContent';
import IntroImage from './components/IntroImage';

const Introduction = ({ i18n, country }) => (
  <section className="introduction">
    <IntroImage />
    <IntroContent i18n={i18n} country={country} />
  </section>
);

Introduction.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  country: PropTypes.string,
};

export default Introduction;
