const textsBr = (i18n) => ({
  HeaderContent_title: i18n.gettext(
    'Conviértete en desarrollador asociado y gana dinero con las integraciones de Mercado Pago',
  ),
  HeaderContent_details: i18n.gettext(
    'Gana dinero con las nuevas integraciones que realices y únete a nuestra comunidad global exclusiva, donde recibirás apoyo de otros profesionales y acceso a actualizaciones especiales.',
  ),
  EconomicContent_details: i18n.gettext(
    'Los partners que estén en el nivel Silver o superior podrán crear sus perfiles en nuestro Centro de Partners.',
  ),
  headerHero_description: i18n.gettext(
    'Recibe dinero con las nuevas integraciones que realices y ten acceso a soporte técnico exclusivo y otras ventajas para tu agencia y tus clientes. Todo gratis.',
  ),
  Developers_tittle_details: i18n.gettext(
    'Puedes ganar dinero participando de nuestro Programa',
  ),
  steps_3: i18n.gettext(
    'Tu agencia recibe un kit de bienvenida y materiales de onboarding',
  ),
  footer_description: i18n.gettext(
    'Ten acceso a tasas diferenciadas, soporte técnico y muchos otros beneficios exclusivos para tu agencia y tus clientes. Es gratis.',
  ),
  invitations_title: i18n.gettext(
    '¿Ya ofreciste nuestro Checkout? Recibe tu identificación única y conviértete en un partner oficial y homologado.',
  ),

  LevelsInfo_OurLevels_certified: i18n.jsx.gettext(
    '{0}Entre R$ 0 y R$ 19.999/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_Silver: i18n.jsx.gettext(
    '{0}Entre R$ 20.000 y R$ 59.999/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_Gold: i18n.jsx.gettext(
    '{0}Entre R$ 60.000 y R$ 199.000/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_Platinum: i18n.jsx.gettext(
    '{0}Más de R$ 200.000/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_certified_period: i18n.jsx.gettext(
    '{0}Cada 3 meses{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_Silver_period: i18n.jsx.gettext('{0}Cada 6 meses{1}', {
    tags: {
      0: '<b>',
      1: '</b>',
    },
  }),
  LevelsInfo_OurLevels_Gold_period: i18n.jsx.gettext('{0}Cada 6 meses{1}', {
    tags: {
      0: '<b>',
      1: '</b>',
    },
  }),
  LevelsInfo_OurLevels_Platinum_period: i18n.jsx.gettext('{0}Cada 6 meses{1}', {
    tags: {
      0: '<b>',
      1: '</b>',
    },
  }),
  membershipForm_personalTitle: i18n.gettext('Datos de la empresa'),
  membershipForm_companyName: i18n.gettext('Nombre de la empresa'),
  membershipForm_companyName_label: i18n.gettext(
    'Ingresa el nombre de la empresa',
  ),
  membershipForm_siteUrl: i18n.gettext('Ingresa el sitio de la empresa'),
  LevelsInfo_Developers_OurLevels_certified: i18n.jsx.gettext(
    '{0}Entre R$ 0 y R$ 19.999/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Silver: i18n.jsx.gettext(
    '{0}Entre R$ 20.000 y R$ 59.999/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Gold: i18n.jsx.gettext(
    '{0}Entre R$ 60.000 y R$ 199.000/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Platinum: i18n.jsx.gettext(
    '{0}Más de R$ 200.000/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  Levels_title: i18n.gettext('Cómo garantizar los incentivos financieros'),

  Levels_first_text_our_levels: i18n.jsx.gettext(
    `Los desarrolladores que participan de nuestro programa reciben una identificación única llamada {3} Integrator ID {4}, que nos permite identificar sus integraciones y darles acceso a las ventajas del programa.{2}{2}\n Agrega tu Integrator ID a tus integraciones, sean desarrollos propios o implementación de checkout para e-commerces creadas en las plataformas WooCommerce, PrestaShop, Adobe Commerce y VTEX.{2}{2}\n Si tienes una plataforma distinta, realiza el {0} registro de tus clientes{1}. {2}{2}\n Si quieres saber más acerca de cómo usar el Integrator ID, {5}accede a nuestra documentación.{6}{2}{2}`,
    {
      tags: {
        0: '<a class="link" href="https://www.mercadopago.com.br/partners/developers/associations" target="_blank">',
        1: '</a>',
        2: '<br>',
        3: '<b>',
        4: '</b>',
        5: '<a class="link" href="https://www.mercadopago.com.br/developers/pt/docs/checkout-pro/additional-content/integration-metrics" target="_blank">',
        6: '</a>',
      },
    },
  ),

  Developers_volver_importante: i18n.jsx.gettext('', {
    tags: {
      0: '<b>',
      1: '</b>',
    },
  }),

  Details_how_it_works: i18n.gettext(
    'Cómo funcionan nuestras bonificaciones financieras',
  ),
  Details_how_it_works_one: i18n.jsx.gettext(
    'Todos los participantes del programa que aprueban una certificación reciben un Integrator ID. Este número de identificación debe {0}ingresarse en todas las integraciones{1} para que podamos validar las operaciones realizadas y contabilizarlas para la bonificación. {2}Saber más{3}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
        2: '<a class="link" href="https://www.mercadopago.com.br/developers/pt/support/38220" target="_blank">',
        3: '</a>',
      },
    },
  ),
  Details_how_it_works_one_agencies: i18n.jsx.gettext(''),
  Details_how_it_works_two: i18n.gettext(
    'Mercado Pago hará un seguimiento del volumen mensual de transacciones y pagos de cada nueva integración que realices durante los primeros 60 días de operación.',
  ),
  Details_how_it_works_three: i18n.gettext(
    'Según la facturación mensual promedio de tus clientes en esos dos períodos, recibirás la bonificación correspondiente.',
  ),
  // Patforms Levels Table
  LevelsInfo_Platform_OurLevels_certified: i18n.jsx.gettext(''),
  LevelsInfo_Platform_OurLevels_Silver: i18n.jsx.gettext(''),
  LevelsInfo_Platform_OurLevels_Gold: i18n.jsx.gettext(''),
  LevelsInfo_Platform_OurLevels_Platinum: i18n.jsx.gettext(''),
  LevelsInfo_Developers_OurLevels_certified_period: i18n.jsx.gettext(
    '{0}Cada 3 meses{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Silver_period: i18n.jsx.gettext(
    '{0}Cada 6 meses{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Gold_period: i18n.jsx.gettext(
    '{0}Cada 6 meses{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Platinum_period: i18n.jsx.gettext(
    '{0}Cada 6 meses{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  Introduction_title: i18n.gettext(
    'Aumenta tus resultados como desarrollador autónomo participando de nuestro Partners Program',
  ),
  Footnotes_disclaimer_one: i18n.gettext(
    '¹ Este beneficio está disponible a partir del nivel Silver.',
  ),
  Footnotes_disclaimer_doc: i18n.jsx.gettext('', {}),
  Footnotes_disclaimer_two: i18n.gettext(
    '²Este beneficio está sujeto a volumen de clientes.',
  ),
  LevelsInfo_agencies_title: i18n.gettext(
    'Conoce las ventajas del Partners Program',
  ),
  LevelsInfo_Platforms_title: i18n.gettext(''),
  Benefits_agencies_commercial_title: i18n.gettext('Comercial'),
  Footer_agencies_title: i18n.gettext(
    'Conviertete en una agencia partner de Mercado Pago',
  ),
  LevelsInfo_developers_title: i18n.gettext(
    'Conoce las ventajas del Partners Program para desarrolladores certificados',
  ),
});

export default textsBr;
