import React from 'react';
import PropTypes from 'prop-types';

import ChevronUp24 from '@andes/icons/ChevronUp24';
import { Button } from '@andes/button';
import { Image } from 'nordic/image';

const Footer = ({ i18n, background, isYellowTheme }) => (
  <section className={`footer ${background}`}>
    <Image
      src="developers/home/Footter.svg"
      alt=""
      className="background-rebranding background-rebranding--top"
    />
    <div className="footer--content">
      <h1 className="footer--content-title">
        {i18n.gettext(
          'Conviértete en un desarrollador partner de Mercado Pago',
        )}
      </h1>
      <Button
        hierarchy=""
        className="icon-container"
        data-testid="scroll-top-button"
        aria-label={i18n.gettext('Scroll to top')}
        onClick={(e) => {
          e.preventDefault();
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        <ChevronUp24 color={isYellowTheme ? 'black' : 'white'} />
      </Button>
      <p className="footer--content-details">
        {i18n.gettext(
          'Ten acceso a tasas diferenciadas, soporte técnico y muchos otros beneficios exclusivos para ti y tus clientes. Es gratis.',
        )}
      </p>
      <Button
        className="footer--content-action"
        href="/developers/panel/developer-program#from=partners_developers_footer_button&to=panel_developer_program"
        hierarchy="transparent"
      >
        {i18n.gettext('Quiero participar')}
      </Button>
    </div>
  </section>
);

Footer.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  background: PropTypes.string,
  isYellowTheme: PropTypes.bool,
};

export default Footer;
