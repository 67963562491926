import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';
import { Button } from '@andes/button';

const Steps = ({ i18n }) => (
  <section className="step-certification-main-container" id="developers-steps">
    <div className="step-certification-container">
      <Image
        src="developers/miscelanea.svg"
        alt=""
        className="step-certification-img"
      />
      <h2 className="step-certification-header">
        {i18n.gettext('Cómo participar del Programa')}
      </h2>
      <div className="step-certification-container-body">
        <div className="container-lines">
          <Image src="common/steps/step_1.svg" alt="" />
          <span className="line" />
          <Image src="common/steps/step_2.svg" alt="" />
          <span className="line" />
          <Image src="common/steps/step_3.svg" alt="" />
        </div>
        <div className="container-text">
          <p className="text-box">
            {i18n.gettext(
              'Elige una de nuestras certificaciones y sigue las instrucciones para hacer tu integración de prueba. Tu certificado se emite al instante.',
            )}
          </p>
          <p className="text-box">
            {i18n.gettext(
              'Si se aprueba, recibes tu identificación única en el programa. Úsala en todas las integraciones para volverlas elegibles a bonificación.',
            )}
          </p>
          <p className="text-box">
            {i18n.gettext(
              'Empieza a aprovechar los beneficios del programa, como nuestro Centro de Partners y nuestro soporte técnico prioritario.',
            )}
          </p>
        </div>
      </div>
      <Button
        className="step-action"
        href="/developers/panel/developer-program#from=partners_developers_steps_button&to=panel_developer_program"
      >
        {i18n.gettext('Quiero participar')}
      </Button>
    </div>
  </section>
);

Steps.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
};

export default Steps;
