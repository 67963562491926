const certifications = (i18n, lang) => [
  {
    id: '0',
    image: 'certification1_image.png',
    title: i18n.gettext('Checkout Pro'),
    description: i18n.gettext(
      'Muestra que tienes las habilidades necesarias para integrar la solución de checkout número uno de Mercado Pago.',
    ),
    requirements: [
      { id: '0', value: i18n.gettext('API REST') },
      { id: '1', value: 'PHP, .NET, Java o Node.Js' },
      { id: '2', value: i18n.gettext('Configuraciones básicas de servidores') },
    ],
    link: `/partners/developers/${lang}/certifications/checkout-pro#from=partners_developers_certification_button&to=checkout_pro`,
    type: 'CHECKOUT-PRO',
  },
  {
    id: '1',
    image: 'certification2_image.png',
    title: i18n.gettext('WooCommerce'),
    description: i18n.gettext(
      'Demuestra tu experiencia en la integración del plugin Mercado Pago en tiendas de la plataforma WooCommerce.',
    ),
    requirements: [
      { id: '0', value: i18n.gettext('PHP, .NET, Java o Node.Js') },
      { id: '1', value: i18n.gettext('Configuraciones básicas de servidores') },
      { id: '2', value: i18n.gettext('Conocimientos sobre Woocommerce') },
    ],
    link: `/partners/developers/${lang}/certifications/woocommerce#from=partners_developers_certification_button&to=woocommerce`,
    type: 'WOOCOMMERCE',
  },
  {
    id: '2',
    image: 'certification3_image.png',
    title: i18n.gettext('Adobe Commerce'),
    description: i18n.gettext(
      'Pon a prueba tus conocimientos en la integración del plugin Mercado Pago en tiendas de la plataforma Adobe Commerce.',
    ),
    requirements: [
      { id: '0', value: i18n.gettext('PHP, .NET, Java o Node.Js') },
      { id: '1', value: i18n.gettext('Configuraciones básicas de servidores') },
      { id: '2', value: i18n.gettext('Conocimientos sobre Adobe Commerce') },
    ],
    link: `/partners/developers/${lang}/certifications/adobe-commerce#from=partners_developers_certification_button&to=adobe_commerce`,
    type: 'MAGENTO',
  },
];

const getCertifications = (i18n, siteId, lang) =>
  certifications(i18n, lang).filter(
    (item) => !item.sites || item.sites.includes(siteId.toUpperCase()),
  );

export { getCertifications };
